import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { isClient } from 'next-utils'
import { useContentful } from 'react-contentful'
import { initContentful } from 'next-contentful'
import { fieldsParser } from 'contentful-parsers'
import PageContainer from 'components/layout/PageContainer'
import Header from 'components/header/Header'
import Footer from 'components/footer/Footer'
import { ComponentRenderer, renderMeta } from 'libs/contentful'

const Home = ({ contentfulData }) => {
  const router = useRouter()

  const { data } = useContentful({
    contentType: 'route',
    parser: (parserData) => (parserData?.items?.[0] && fieldsParser(parserData.items[0])) || null,
    query: {
      'fields.slug[in]': router.asPath.replace(/\?.*/, ''),
    },
    skip: !isClient(),
  })

  const pageData = contentfulData || data

  return (
    <PageContainer>
      <Head>
        <title>Beatnic</title>
        {pageData?.page?.meta && renderMeta(pageData?.page?.meta)}
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      {pageData?.page?.sections && <ComponentRenderer data={pageData?.page?.sections} />}
      <Footer />
    </PageContainer>
  )
}

Home.getInitialProps = async (ctx) => {
  const { params, query, asPath } = ctx

  const contentfulClient = initContentful({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    accessToken: params?.preview
      ? process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN_PREVIEW
      : process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN_DELIVERY,
    host: query?.preview
      ? 'preview.contentful.com'
      : 'cdn.contentful.com',
  })

  const contentfulResponse = await contentfulClient.getEntries({
    content_type: 'route',
    'fields.slug[in]': asPath.replace(/\?.*/, ''),
    include: 10,
  })

  const contentfulData = contentfulResponse?.items?.[0]
    ? fieldsParser(contentfulResponse.items[0])
    : null

  const contentfulCache = contentfulClient.cache.extract()

  return {
    contentfulCache,
    contentfulData,
  }
}

export default Home
