const renderMeta = (data) => data.map((item) => {
  if (item.type === 'title') {
    return <title>{item?.metaName}</title>
  }

  if (item?.metaName === 'og:image') {
    const http = item?.media?.file?.url
      ? `http:${item.media.file.url}`
      : item?.metaContent
    const https = item?.media?.file?.url
      ? `https:${item.media.file.url}`
      : item?.metaContent

    return (
      <>
        <meta
          key={`${item.id}-http`}
          property="og:image"
          content={http}
        />
        <meta
          key={`${item.id}-https`}
          property="og:image:secure_url"
          content={https}
        />
      </>
    )
  }

  if (item?.metaName?.startsWith('og:')) {
    return (
      <meta
        key={item.id}
        property={item?.metaName}
        content={item?.media?.file?.url || item?.metaContent}
      />
    )
  }

  return (
    <meta
      key={item.id}
      name={item?.metaName}
      content={item?.media?.file?.url || item?.metaContent}
    />
  )
})

export default renderMeta
